.videoback{
    height: 109vh;
    width: 100%;
    object-fit: contain;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    margin-top: -20rem;
}

.video {
    object-fit: cover;
    width: 100%;
    height: 109%;
    position: absolute;
    z-index: -1;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ================== */

@media screen and (max-width: 1024px) {

    .videoback{
        height: 126vh;
        
    }
    
    .video {
        height: 126vh;
        
    }
   
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ================== */

@media screen and (max-width: 600px) {
    

    
}