.videoback1{
    height: 500vh;
    width: 100%;
    object-fit: contain;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    margin-top: -15rem;
    position: relative;
    left: 0rem;
    top: 2rem;
}

.video1 {
    height: 500vh;
    width: 100%;
    margin-top: -100rem;
    position: relative;
    left: 0rem;
    top: 10rem;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ================== */

@media screen and (max-width: 1024px) {

    .videoback1{
        height: 190vh;
        
    }
    
    .video {
        height: 190vh;
        
    }
   
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ================== */

@media screen and (max-width: 600px) {
    .videoback1{
        height: 360vh;
        
    }
    
    .video {
        height: 360vh;
        
    }

    
}