.portfolio__container1{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2.5rem;
}

.portfolio__item1 {
    background: var(--color-bg-variant);
    padding: 0;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item1:hover{
    border-color: var(--color-primary-varient);
    background: transparent;
}

.portfolio__item-image1{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item1 h3 {
    margin: 1.2 0 2rem;
}

.portfolio__item-cta1 {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.descripcionn1{
    padding-top: 0.5rem;
    padding-bottom: 2rem;
}
.title1{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title2{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
    text-align: center;
}

.title3{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title4{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title5{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title6{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title7{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title8{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title9{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title10{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title11{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title12{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title13{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}
.title14{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}


.boton1{
    position: relative;
    left: 0.5rem;
    top: 0rem; 
}

.botonModal{
    width: 95%; /* Ancho del contenedor, ajusta según tus necesidades */
    display: grid;
    place-items: center;
}



/* ============== MEDIA QUERIES (MEDIUM DEVICES) ================== */

@media screen and (max-width: 1024px) {
   .portfolio__container1{
       grid-template-columns: 1fr 1fr;
       gap: 1.2rem;
   }
   .title1{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title2{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
    text-align: center;
}

.title3{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title4{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title5{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title6{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title7{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title8{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title9{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title10{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title11{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title12{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}

.title13{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}
.title14{
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
    left: 0rem;
    gap: 20px;
   text-align: center;
    
}


.boton1{
    width: 250px; /* Ancho del contenedor, ajusta según tus necesidades */
    display: grid;
    place-items: center;
}

.title6{
    padding-bottom: 2.5rem;
    
}


 }
 
 /* ============== MEDIA QUERIES (SMALL DEVICES) ================== */
 
 @media screen and (max-width: 600px) {
    .portfolio__container1 {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
 }