.Encabezado{
    z-index: 1;
    height: 30rem;
    position: absolute;
    left: calc(50% - 25rem);
    margin-top: 2rem;
    border-radius: 12rem 12rem 0 0;
    padding: 5rem 1.5rem 1.5rem;
    font-size: 3rem;
    font-family: 'PT Serif', serif;
    text-align: center;
}

.cuerpo{
    z-index: 1;
    text-align: justify;
    height: 30rem;
    position: absolute;
    left: calc(50% - 30rem);
    margin-top: 15rem;
    margin-right: 20rem;
    border-radius: 12rem 12rem 0 0;
    padding: 5rem 1.5rem 1.5rem;
    font-size: 1rem;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ================== */

@media screen and (max-width: 1024px) {
    .Encabezado{
        margin-top: 15rem;
    }
    
    .cuerpo{
        left: calc(50% - 20rem);
        margin-top: 35rem;
        margin-right: 5rem;
    }
  }
  
  /* ============== MEDIA QUERIES (SMALL DEVICES) ================== */
  
  @media screen and (max-width: 600px) {
    .Encabezado{
        font-size: 2rem;
        margin-top: 15rem;
        left: calc(50% - 13rem);
    }
    
    .cuerpo{
        left: calc(50% - 12rem);
        margin-top: 35rem;
        margin-right: 1rem;
    }
  }