.logo{
    width: 120px;
    margin-left: 20px;
    margin-top: 100px;
    z-index: 1;
}

nav{
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.nav-menu a {
    color: rgb(0, 0, 0);
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
}

.nav-menu li a.active, .nav-menu li a:hover {
    color: #F9D407;
    transition: .3s;
}

#check {
    display: none;
}

.bar-btn{
        display: none;
}

.nav-menu{
        position: relative;
        height: 100px;
        top: 0;
        background: none;
        transition: none;
        text-align: right;
        margin-right: 50px;
        z-index: 1;
}

.nav-menu li {
        display: inline-block;
        line-height: 100px;
        margin: 0 20px;
        transition: none;
}

.nav-menu li a {
        font-size: 12px;
        color: #fff;
}



.header__container{
    text-align: center;
    height: 100%;
    position: relative;
    
}


/* ============= CTA ============= */

.cta {
    
    height: 30rem;
    position: absolute;
    left: calc(50% - 11.98rem);
    margin-top: 18rem;
    border-radius: 12rem 12rem 0 0;
    padding: 5rem 1.5rem 1.5rem;
    gap: 1.2rem;
    justify-content: center;
}

.separa {
    margin: 1rem;
}

.videofondo {
    position: absolute;
    width:auto;
    top: 10rem;
    height:auto;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
    padding: none;
    filter: blur(5px);
    /*border-radius: 83% 17% 44% 56% / 100% 0% 100% 0% ;*/
    
}


/* ============== MEDIA QUERIES (MEDIUM DEVICES) ================== */

@media screen and (max-width: 1024px) {
    
.logo{
    width: 100px;
    margin-left: 20px;
    margin-top: 15px;
}
.texto{
    margin-top: 5rem;
    
}

.bar-btn{
    display: block;
}

.nav-menu li a {
    font-size: 15px; 
    color: rgb(0, 0, 0);
}

nav{
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    top: 5%;
    left: 15%;
    transform: translateX(-50%);
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    margin-left: 2rem;
}

.bar-btn {
    font-size: 24px;
    color: #fff;
    margin-right: 30px;
    cursor: pointer;
}

.cta{
    margin-top: 18rem;
    
}

.nav-menu{
    
    position: absolute;
    width: 15rem;
    height: 0;
    top: 110px;
    left: 0px;
    background: rgba(255, 255, 255, 0.95);
    text-align: center;
    transition: all .5s;
    border-radius: 3rem;
}

.nav-menu li {
    display: none;
    line-height: 30px;
    margin: 50px 0;
    transition: all .5s;
}

.nav-menu li a.active, .nav-menu li a:hover {
    color: #F9D407;
    transition: .3s;
}

#check {
    display: none;
}

#check:checked ~ .nav-menu {
    height: auto;
}

#check:checked ~ .nav-menu li {
    display: block;
}
  }
  
  /* ============== MEDIA QUERIES (SMALL DEVICES) ================== */
  
  @media screen and (max-width: 600px) {
    
    nav{
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: auto;
        left: 50%;
        bottom: 2rem;
        border-radius: 3rem;
        backdrop-filter: blur(15px);
        margin-left: 0;
        position: fixed;
    }

    .nav-menu{
        position: absolute;
        width: 15rem;
        height: 0;
        top: -531px;
        left: 0px;
        background: rgba(255, 255, 255, 0.95);
        text-align: center;
        transition: all .5s;
        border-radius: 5rem;
    }
  }
  
