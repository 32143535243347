.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

.container{
    margin: 20rem;
}
/* ============= TEXTO ============== */

.texto {
    height: 30rem;
    position: absolute;
    left: calc(50% - 11.98rem);
    margin-top: 10rem;
    border-radius: 12rem 12rem 0 0;
    padding: 5rem 1.5rem 1.5rem;
    font-size:1.5rem;
}

.unu {
    color: #F9D407;
}


/* ============= CTA ============= */

.cta {
    
    height: 30rem;
    position: absolute;
    left: calc(50% - 13.41rem);
    margin-top: 20rem;
    border-radius: 12rem 12rem 0 0;
    padding: 5rem 1.5rem 1.5rem;
    gap: 1.2rem;
    justify-content: center;
}
.espa{
    margin-top: 2rem;
}

.separa {
    margin: 2rem;
}

.videofondo {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;
    z-index: -1;
    filter: blur(3px);
    /*border-radius: 83% 17% 44% 56% / 100% 0% 100% 0% ;*/
    
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ================== */

@media screen and (max-width: 1024px) {

    .texto{
        left: calc(50% - 11.98rem);
    margin-top: 30rem;
    
    }
    .cta{
        left: calc(50% - 13rem);
    margin-top: 40rem;
    
    }
   
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ================== */

@media screen and (max-width: 600px) {
    .texto{
        left: calc(50% - 11.98rem);
    margin-top: 25rem;
    
    }
    .cta{
        left: calc(50% - 12rem);
    margin-top: 40rem;
    
    }
    
}
